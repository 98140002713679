@use './assets/scss/theme-variables.module.scss' as *;

.fill-blue {
    fill: var(--colorBlue);
}

.fill-primary-light {
    fill: var(--primaryLight);
}
.stroke-primary-light {
    stroke: var(--primaryLight);
}

.fill-primary-main {
    fill: var(--primaryMain);
}
.stroke-primary-main {
    stroke: var(--primaryMain);
}

.fill-secondary-green {
    fill: var(--secondaryGreen);
}

.fill-arrow-yellow {
    fill: var(--arrowYellow);
}

.fill-secondary-main {
    fill: var(--secondaryMain);
}

.fill-old-silver-grey {
    fill: var(--oldSilverGrey);
}

.fill-failed-red {
    fill: var(--failedRed);
}

.fill-pdf-red {
    fill: var(--pdfRed);
}

.fill-negative-red {
    fill: var(--negativeRed);
}

.fill-secondary-orange-yellow {
    fill: var(--secondaryOrange);
}
.fill-orange {
    fill: var(--colorOrange);
}

.fill-newstreet-red {
    fill: var(--newstreetRed);
}

.fill-shecom-pink {
    fill: var(--shecomPink);
}

.fill-success-green {
    fill: var(--successGreen);
}
.fill-positive-green {
    fill: var(--positiveGreen);
}

.fill-white {
    fill: var(--colorWhite);
}
