@use './mixins/rem' as *;

$breakpoint-up: (
  xs: rem(0),
  sm: rem(768),
  md: rem(992),
  lg: rem(1200),
  xlg: rem(1365),
  xl: rem(1536),
  xxl: rem(1800),
);

$breakpoint-down: (
  xs: rem(576),
  sm: rem(767),
  md: rem(991),
  lg: rem(1199),
  xlg: rem(1364),
  xl: rem(1535),
  xxl: rem(1799),
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoint-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoint-down, $size)) {
    @content;
  }
}
