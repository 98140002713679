
$font-file-path: '../../fonts' !default;

@font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 100;
    src: url('#{$font-file-path}/metropolis-all-100-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-100-italic.woff') format('woff');
    
  }
  /* metropolis-all-100-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: url('#{$font-file-path}/metropolis-all-100-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-100-normal.woff') format('woff');
    
  }
  /* metropolis-all-200-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 200;
    src: url('#{$font-file-path}/metropolis-all-200-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-200-italic.woff') format('woff');
    
  }
  /* metropolis-all-200-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    src: url('#{$font-file-path}/metropolis-all-200-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-200-normal.woff') format('woff');
    
  }
  /* metropolis-all-300-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: url('#{$font-file-path}/metropolis-all-300-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-300-italic.woff') format('woff');
    
  }
  /* metropolis-all-300-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url('#{$font-file-path}/metropolis-all-300-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-300-normal.woff') format('woff');
    
  }
  /* metropolis-all-400-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: url('#{$font-file-path}/metropolis-all-400-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-400-italic.woff') format('woff');
    
  }
  /* metropolis-all-400-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('#{$font-file-path}/metropolis-all-400-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-400-normal.woff') format('woff');
    
  }
  /* metropolis-all-500-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 500;
    src: url('#{$font-file-path}/metropolis-all-500-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-500-italic.woff') format('woff');
    
  }
  /* metropolis-all-500-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('#{$font-file-path}/metropolis-all-500-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-500-normal.woff') format('woff');
    
  }
  /* metropolis-all-600-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 600;
    src: url('#{$font-file-path}/metropolis-all-600-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-600-italic.woff') format('woff');
    
  }
  /* metropolis-all-600-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url('#{$font-file-path}/metropolis-all-600-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-600-normal.woff') format('woff');
    
  }
  /* metropolis-all-700-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 700;
    src: url('#{$font-file-path}/metropolis-all-700-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-700-italic.woff') format('woff');
    
  }
  /* metropolis-all-700-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('#{$font-file-path}/metropolis-all-700-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-700-normal.woff') format('woff');
    
  }
  /* metropolis-all-800-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 800;
    src: url('#{$font-file-path}/metropolis-all-800-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-800-italic.woff') format('woff');
    
  }
  /* metropolis-all-800-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: url('#{$font-file-path}/metropolis-all-800-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-800-normal.woff') format('woff');
    
  }
  /* metropolis-all-900-italic*/
  @font-face {
    font-family: 'Metropolis';
    font-style: italic;
    font-display: swap;
    font-weight: 900;
    src: url('#{$font-file-path}/metropolis-all-900-italic.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-900-italic.woff') format('woff');
    
  }
  /* metropolis-all-900-normal*/
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url('#{$font-file-path}/metropolis-all-900-normal.woff2') format('woff2'), url('#{$font-file-path}/metropolis-all-900-normal.woff') format('woff');
    
  }
  