.dashboard {
    display: grid;
    grid-template-columns: 240px auto ; 
    height: 100vh; 

    .products {
        padding: 10px;
        background-color: #fff;
    }

    .data-components {
        display: grid;
        grid-template-rows: 5.5% 29% 43% auto;
        height: 100vh;
        background-color: #f3f3f3;
        margin-left: 10px;




        .row {
            display: flex;
            height: 100%;

            
            &.full-width {
                width: 100%;
                div {
                    width: 100%;
                   
                }
            }

            .column1 {
                width: 55%;
              
                margin : 5px;
            }

            .column2 {
                width: 45%;
            
                margin : 5px;
            }
        }
    }


    @media (max-width: 850px) {
        .products, .data-components {
            padding: 0;
        }

        .data-components {
            .row {
                flex-direction: column;
                height: auto;

                .column1,
                .column2 {
                    width: 100%;
                    margin: 0;
                }

                &.full-width {
                    div {
                        width: 100%;
                    }
                }
            }
        }
    }

}
