@use '../theme-variables.module.scss' as *;



$customerSupportHeaderHeight: 50px;
$customerSupportPaginationHeight: 50px;
$customerSupportGridGap: 10px;

.customer-support-containter {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(400px, 4fr);
    grid-template-rows: $customerSupportHeaderHeight 1fr $customerSupportPaginationHeight;
    height: calc(100vh - $headerHeight - 2 * $gridGap);
    width: calc(100vw - $sideBarWidth - 2 * $gridGap);
    grid-gap: $customerSupportGridGap;
}

.customer-support-header {
    margin-top: '3rem';
    grid-area: 1/1/2/3;
    display: flex;
    align-items: center;
}


.customer-support-filter {
    background-color: var(--colorWhite);
    grid-area: 2/1/3/2;
    height: calc(
        100vh - $headerHeight - 2 * $gridGap - 2 * $customerSupportGridGap - 105px
    );
    margin-top: 1vh;
}

.customer-support-group-list {
    grid-area: 2/2/3/3;
    height: calc(
        100vh - $headerHeight - 2 * $gridGap - 2 * $customerSupportGridGap - 100px
    );
    overflow-y: scroll;
    margin-top: 1vh;
}

.customer-support-pagination {
    grid-area: 3/1/4/3;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* For Firefox and newer versions of Edge */
.customer-support-group-list {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888 #f0f0f0; /* Color of the thumb and track */
}
