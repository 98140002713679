@use '../theme-variables.module.scss' as *;
@use '../core/mixins/rem' as *;
@use '../core/breakpoints' as *;

.app-sample {
  flex-grow: 1;
  background-attachment: fixed;
  padding: rem(105) rem(90);
  display: flex;
  flex-direction: column;
  position: relative;

  @include breakpoint-down(xl) {
    padding: rem(64);
  }

  @include breakpoint-down(xlg) {
    padding: rem(50);
  }

  @include breakpoint-down(lg) {
    padding: rem(40);
  }

  @include breakpoint-down(sm) {
    // background: none;
    // padding-bottom: 0;
    padding: rem(30) rem(30) rem(135);
  }
}
