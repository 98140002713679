@use '../theme-variables.module.scss' as *;

$reportsPaginationHeight: 50px;
$reportsGridGap: 10px;

.reports-container {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(400px, 4fr);
    grid-template-rows: 1fr $reportsPaginationHeight;
    height: calc(100vh - $headerHeight - 9rem - 2 * $gridGap);
    width: calc(100vw - $sideBarWidth - 2 * $gridGap);
    grid-gap: $reportsGridGap;
}
.cus-dashboard-header {
    background-color: transparent;
    grid-area: 1/1/2/3;
    display: flex;
    align-items: center;
    margin-top:'3rem';
}
.cus-dashboard-container {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(400px, 4fr);
    grid-template-rows: 1fr $reportsPaginationHeight;
    height: calc(100vh - $headerHeight - 3.5rem - 2 * $gridGap);
    width: calc(100vw - $sideBarWidth - 2 * $gridGap);
    grid-gap: $reportsGridGap;
}

.cus-dashboard-filter {
    background-color: var(--colorWhite);
    grid-area: 1/1/2/2;
    height: calc(100vh - $headerHeight - 8.5rem - 2 * $gridGap);
}

.reports-filter {
    background-color: var(--colorWhite);
    grid-area: 1/1/2/2;
    height: calc(100vh - $headerHeight - 13rem - 2 * $gridGap);
}

.reports-group-list {
    grid-area: 1/2/2/3;
    height: calc(100vh - $headerHeight - 13rem - 2 * $gridGap);
    overflow-y: scroll;
}

.reports-pagination {
    grid-area: 2/1/3/3;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* For Firefox and newer versions of Edge */
.reports-group-list {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888 #f0f0f0; /* Color of the thumb and track */
}


.customer-dashboard-list {
    grid-area: 1/2/2/3;
    // height: calc(100vh - $headerHeight - 9rem - 2 * $gridGap);
    overflow-y: scroll;
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888 #f0f0f0; /* Color of the thumb and track */
}

.customer-dashboard-pagination {
    grid-area: 2/1/3/3;
    display: flex;
    align-items: center;
    justify-content: center;
}

