@use '../core/variables' as *;
@use '../theme-variables.module.scss' as *;

// Normalize.css or CSS Reset
@import 'normalize.css';

@font-face {
    font-family: $body-font; /*Can be any text*/
    src: local('Metropolis'),
        url('../../fonts/Metropolis-Regular.otf') format('opentype');
}
.flex-center {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
// Global Styles
html {
    overflow: hidden;
    font-size: 12px;
}
*,
body {
    font-family: $body-font;
    font-size: 12px;
    margin: 0;
    padding: 0;
    user-select: none;
    scrollbar-width: thin;

    // scrollbar-gutter: stable both-edges;
    :hover {
        /* Apply custom scrollbar styles on hover */
        scrollbar-color: #888 #ddd;
    }

    /* Define the scrollbar thumb and track styles */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Width of the scrollbar */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #e1dbdb; /* Color of the scrollbar thumb */
        border-radius: 50px; /* Rounded corners for the thumb */
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #838383; /* Color of the scrollbar thumb on hover */
    }

    ::-webkit-scrollbar-track {
        background-color: #ddd; /* Color of the scrollbar track */
    }

    ::-webkit-scrollbar-track-piece {
        background-color: #eee; /* Color of the visible portion of the track */
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.button-prefix-icon {
    fill: var(--primaryMain);
}

.header-btn:hover .button-prefix-icon {
    fill: var(--colorWhite);
}
.header-btn:disabled .button-prefix-icon {
    fill: var(--colorDarkGrey);
}

.horizontal-tabs {
    button {
        @media (min-width: 1200px) {
            font-size: 1rem;
        }
    }
}

@media (min-width: 320px) {
    html {
        font-size: 8px;
    }
}

@media (min-width: 481px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 769px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 1025px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 1201px) and (max-width: 5000px) {
    html {
        font-size: 12px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 60s;
}
