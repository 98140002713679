@use '../theme-variables.module.scss' as *;


$loanApprovalHeaderHeight: 50px;
$loanApprovalPaginationHeight: 50px;
$loanApprovalGridGap: 10px;

.loan-approval-containter {
    display: grid;
    grid-template-columns: minmax(250px, 1fr) minmax(400px, 4fr);
    grid-template-rows: $loanApprovalHeaderHeight 1fr $loanApprovalPaginationHeight;
    height: calc(100vh - $headerHeight - 2 * $gridGap);
    width: calc(100vw - $sideBarWidth - 2 * $gridGap);
    grid-gap: $loanApprovalGridGap;
}

.loan-approval-header {
    background-color: transparent;
    grid-area: 1/1/2/3;
    display: flex;
    align-items: center;
}

.loan-approval-filter {
    background-color: var(--colorWhite);
    grid-area: 2/1/3/2;
    height: calc(
        100vh - $headerHeight - 2 * $gridGap - 2 * $loanApprovalGridGap - 105px
    );
    margin-top: 1vh;
}

.loan-approval-group-list {
    grid-area: 2/2/3/3;
    height: calc(
        100vh - $headerHeight - 2 * $gridGap - 2 * $loanApprovalGridGap - 100px
    );
    overflow-y: scroll;
    margin-top: 1vh;
}

.loan-approval-pagination {
    grid-area: 3/1/4/3;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* For Firefox and newer versions of Edge */
.loan-approval-group-list {
    scrollbar-width: thin; /* Width of the scrollbar */
    scrollbar-color: #888 #f0f0f0; /* Color of the thumb and track */
}
