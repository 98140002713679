.filter-container {
    height: inherit;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-left: 0.813rem;
}

.filter-header {
    height: 3.425rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.filter-chips-container {
    max-height: 12.5rem;
    display: flex;
    flex-direction: column;
    animation: expand 0.5s ease-in-out;
    
    margin: 10px 0px 5px 0px;
}

@keyframes expand {
    from {
        transform: scale(0);
        opacity: 0;
    }
}
.filter-chips-header {
    height: 1.875rem;
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    padding-left: 2.5%;
}

.filter-chips-body {
    flex: 1;
    overflow: auto;
    padding-left: 2.5%;
}

.filter-underline {
    width: 92%;
    height: 0.5px;
    stroke-width: 1px;
    stroke: rgb(245, 244, 244);
    flex-shrink: 0;
    background-color: rgb(245, 244, 244);
    margin-left: 3%;
}

.filter-chip-wrapper {
    height:1.6rem;
    display: inline-flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    font-weight: 400;
    padding: 5px;
    margin: 6px 6px 0px 0px;
    background-color: #f3f3f3;
    cursor: pointer;

}
.filter-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin:auto 3px auto 3px;
    gap:9px;
}

.filter-body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 1.2rem;
    overflow-x: hidden;
}

.filter-underline {
    width: 92%;

    height: 0.5px;

    stroke-width: 1px;

    stroke: rgb(245, 244, 244);

    flex-shrink: 0;

    background-color: rgb(245, 244, 244);

    margin-left: 3%;
}


.filter-body::-webkit-scrollbar{
    display: none;
}