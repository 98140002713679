


.income-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.swipable-info-div {
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.creditreport-iconincard {
    display: flex;
    flex-direction: row;
    margin-left: 1.5rem;
}
.creditruleengine-disbursalcard {
    padding: 1rem;
}

.underline {
    width: 27px;
    height: 3px;
    flex-shrink: 0;
    background-color: var(--primaryLight);
    margin-top: 3px;
    margin-bottom: 15px;
}

